<template>
  <cs-dialog
    ref="dialog"
    class="com-user-MoreMessage"
    :title="title"
    width="720px"
    :submit="submit"
    submitText="好，我知道了"
  >
    <h1 class="title">{{ formModel.title }}</h1>
    <div class="date">{{ formModel.date }}</div>
    <p class="content" v-html="formModel.content"></p>
  </cs-dialog>
</template>
<script>
export default {
  props: {
    title: String,
    data: Object,
  },
  data() {
    return {
      formModel: {
        title: "",
        date: "",
        content: "",
        ...this.data,
      },
    };
  },
  methods: {
    async submit() {},
  },
};
</script>
<style lang="less" scoped>
.com-user-MoreMessage {
  /deep/ .ant-modal-body {
    .title {
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: rgba(31, 31, 31, 0.85);
      letter-spacing: 0;
      line-height: 20px;
      font-weight: bold;
    }

    .content {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: rgba(51, 51, 51, 0.85);
      letter-spacing: 0;
      line-height: 26px;
    }

    .date {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  /deep/ .dialog-btn-cancel {
    display: none;
  }
}
</style>